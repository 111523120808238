import React from 'react'
import { ButtonBase as MuiButtonLink } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"

const styles = ({ palette }) => ({
    root: {
        fontFamily: 'Rubik',
        fontSize: '14px',
    }
});

function ButtonLink({ children, className = "", ...rest }) {
    const classes = makeComponentStyles(styles)
    const props = {
        ...rest,
        className: clsx(classes.root, className),
        disableRipple: true
    }
    return (
        <MuiButtonLink
            {...props}
        >
            {children}
        </MuiButtonLink>
    )
}

export default ButtonLink;