import React from "react"
import { makeComponentStyles } from "@components/mixins.js"
import { Typography } from "@material-ui/core"
import Container from "@components/Container/Container.jsx"
import Link from "@components/Buttons/Link.jsx"
import IconButton from "@components/Buttons/IconButton.jsx"
import Grid from "@material-ui/core/Grid"
import clsx from "clsx"
import LogoDesktop from "@static/images/logo/LogoTSU.svg"
import LogoMobile from "@static/images/logo/LogoTSU_mobile_footer.svg"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../../heplers"
import { graphql, useStaticQuery } from "gatsby"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import { AnchorLink } from "gatsby-plugin-anchor-links"
// import { navigate } from "@reach/router";
const styles = ({ palette, breakpoints }) => ({
  root: {
    background: palette.primary.main,
    color: palette.white.main,
    [breakpoints.down("sm")]: {
      background: palette.white.main,
      color: palette.text.muted
    }
  },
  visible: {
    [breakpoints.up("md")]: {
      display: "none"
    }
  },
  visible_reverse: {
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  container_center: {
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  footer_logo: {
    [breakpoints.down("sm")]: {
      padding: "17px 0 !important"
    },
    [breakpoints.up("md")]: {
      paddingTop: "0 !important",
      alignItems: "flex-start",
      marginRight: "20px"
    },
    marginRight: "10px"
  },
  icon: {
    content: `url(${LogoMobile})`,
    [breakpoints.up("md")]: {
      content: `url(${LogoDesktop})`
    }
  },
  horizontCenter: {
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  textbox: {
    width: "688px",
    paddingBottom: "42px",
    paddingTop: "30px"
  },
  linkColor: {
    fontSize: "16px !important",
    color: `${palette.white.main} !important`,
    [breakpoints.down("sm")]: {
      color: `${palette.primary.main} !important`
    }
  }
})

const request = graphql`{
    allStrapiFooter {
        edges {
            node {
                locale
                LinkFooter {
                    Link
                    LinkName
                    File {
                      localFile {
                        publicURL
                      }
                    }
                }
                SocialFooter {
                    SocialURL
                    ImageSocial {
                        localFile {
                            publicURL
                        }
                    }
                }
                Description
            }
        }
    } 
}`

function go(link) {
  window.open(link, "_blank")
}

const Footer = ({ className }) => {
  const {
    root,
    visible,
    visible_reverse,
    container_center,
    footer_logo,
    icon,
    textbox,
    linkColor
  } = makeComponentStyles(styles)

  const intl = useIntl()
  const data = useStaticQuery(request)

  const {
    LinkFooter,
    SocialFooter,
    Description
  } = getStrapiContentByLang(data.allStrapiFooter.edges, intl.locale)

  const appendix = (
    <Typography style={{ fontSize: "14px" }}>
      {Description}
    </Typography>
  )
  const scrollMainPage = async () => {
    navigate('/#main_contactus')
    // let el = document.getElementById("main_contactus");
    // el.scrollIntoView({block: "center", behavior: "smooth"});
  }


  return (
    <footer className={clsx(root, className)}>
      <hr className={visible} />
      <Container className={container_center} style={{ paddingTop: "20px" }}>
        <IconButton className={footer_logo} color="basic" onClick={() => navigate("/")}>
          <div className={icon} />
        </IconButton>

        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <Grid item md={8}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={3}>
                <Link to="/users/account/appointment"
                      className={linkColor}>{intl.formatMessage({ id: "languageclubs" })}</Link>
              </Grid>
              <Grid xs={6} item md={3}>
                  <Link className={linkColor} to={'/#main_contactus'}>{intl.formatMessage({ id: "contactus" })}</Link>
              </Grid>
              <Grid xs={6} item md={3}>
                <Link to="/photo_gallery" className={linkColor}>{intl.formatMessage({ id: "photogallery" })}</Link>
              </Grid>
              <Grid item xs={6} md={3}>
                <Link to="/users/account/appointment"
                      className={linkColor}>{intl.formatMessage({ id: "consulting" })}</Link>
              </Grid>
              <Grid xs={6} item md={3}>
                <Link to="/users/account/appointment"
                      className={linkColor}>{intl.formatMessage({ id: "courses" })}</Link>
              </Grid>
              {
                LinkFooter.map(({ LinkName, Link, File }, index) => (
                  <React.Fragment key={`social-${index}-link`}>
                    <Grid item xs={6} md={3}>
                      <ButtonLink style={{ textAlign: "left" }} className={linkColor}
                                  onClick={() => go(File?.localFile?.publicURL ?? Link)}>{LinkName}</ButtonLink>
                    </Grid>
                  </React.Fragment>
                ))
              }
            </Grid>
            <div className={visible} style={{ padding: "27px 0 37px" }}>{appendix}</div>
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={2}>
              {
                SocialFooter.map(({ ImageSocial, SocialURL }, index) => (
                  <React.Fragment key={`social-${index}-link`}>
                    <Grid item xs={3} md={2}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <IconButton style={{ width: "40px", height: "40px" }}>
                        <ButtonLink onClick={() => go(SocialURL)}>
                          <img src={ImageSocial.localFile.publicURL} alt="social" />
                        </ButtonLink>
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))
              }
            </Grid>
          </Grid>
        </Grid>

      </Container>
      <div className={visible_reverse} align="center">
        <div className={textbox}>{appendix}</div>
      </div>
    </footer>
  )
}

export default Footer
