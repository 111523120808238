import React from 'react';
import { Container as MuiContainer } from '@material-ui/core';

function Container({ children, fluid = false, mw = 'lg', className = "", ...rest }) {

  const maxWidth = fluid ? false : mw;
  const props = { maxWidth, className, ...rest };

  return (
    <MuiContainer {...props}>
      {children}
    </MuiContainer>
  )
}

export default Container;
