import React from 'react'
import { Menu as MuiMenu } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";

const Menu = withStyles(({ palette }) => ({
    paper: {
        background: palette.primary.light,
        color: palette.text.primary,
        borderRadius: "3px"
    }
}))((props) => (
    <MuiMenu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom" }}
        {...props}
    />
));

export default Menu;
