import React, { useContext, useEffect, useState } from "react"
import { Button as MuiButton } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"
import { ValidatorContext } from "../Forms/context.js"

const styles = ({ palette }) => ({
  root: {
    fontSize: '14px',
    padding: "10px 30px",
    borderRadius: "2em",
    boxShadow: "none",
    "&:active": {
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important"
    },
    textTransform: "none"
  },
  btn_primary: {
    "&:hover": {
      backgroundColor: palette.primary.main,
      boxShadow: "0px 4px 22px -5px rgba(0, 114, 187, 0.4)"
    }
  },
  btn_white: {
    color: palette.primary.main,
    backgroundColor: palette.white.main,
    "&:hover": {
      backgroundColor: palette.white.main,
      boxShadow: "0px 4px 22px -5px rgba(255, 255, 255, 0.4)"
    }
  },
  btn_blue: {
    color: palette.white.main,
    backgroundColor: palette.primary.main,
    "&:hover": {
      backgroundColor: palette.white.main,
      boxShadow: "0px 4px 22px -5px rgba(255, 255, 255, 0.4)"
    }
  }
})

function validatorErrors (states, filter){
  return Object.values(states)
    .map(item => item.status)
    .filter(filter);
}

function Button({ children, color = "primary", className = "", disabled = false, ...rest }){
  const classes = makeComponentStyles(styles);
  const context = useContext(ValidatorContext);
  const [isDisabled, setDisabled] = useState(disabled);

  useEffect(() => {
    if(context && context.validate){
      setDisabled(Boolean(validatorErrors(context.states, context.filter).length))
    }
  }, [context])

  const props = {
    ...rest,
    color,
    disabled: isDisabled,
    variant: "contained",
    className: clsx(classes.root, classes[`btn_${color}`], className),
    disableRipple: true,
    disableFocusRipple: true,
  }
  return (
    <MuiButton
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default Button;
