import $api from '../axios.js';

function CONTACT(body){
    return $api.post('/requests/contact', body);
}

function SUGGEST_EVENT(body){
    return $api.post('/requests/event', body);
}

function MEETING_AIRPORT(body){
    return $api.post('/requests/meet', body);
}

function ARRIVAL_NOTIFICATION(body) {
    return $api.post('/requests/meet/notification', body);
}

function GET_SERVICE_ORDERS(lang){
    return $api.get('/requests/service', { params: { lang } });
}

function MAKE_SERVICE_ORDER(body){
    return $api.post('/requests/service', body);
}

function INSURANCE_ORDER(body) {
    return $api.post('/requests/insurance', body);
}

function MAKE_COMMENT_ORDER(body){
    return $api.post('/requests/comment', body);
}

function GET_COMMENT_ORDER(postid){
    return $api.get('/requests/comment', { params: { postid } });
}


export {
    CONTACT,
    SUGGEST_EVENT,
    MEETING_AIRPORT,
    ARRIVAL_NOTIFICATION,
    MAKE_SERVICE_ORDER,
    GET_SERVICE_ORDERS,
    INSURANCE_ORDER,
    MAKE_COMMENT_ORDER,
    GET_COMMENT_ORDER,
}
