import React from 'react';
import { Box, Grid } from "@material-ui/core"
import Image from "@static/images/AuthImage.svg";
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"
import Container from "@components/Container/Container.jsx"
import LogoPrimary from '@static/images/logo/LogoPrimary.svg';
import IconButton from "@components/Buttons/IconButton.jsx"
import { navigate } from "gatsby"

const styles = ({ breakpoints }) => ({
  auth_layout: {
    [breakpoints.up('md')]:{
      height: "100vh",
      overflow: 'hidden'
    }
  },
  auth__container: {
    height: "100%"
  },
  layout__image: {
    height: "100%",
    float: "right"
  },
  layout__content: {
    height: "100%",
  },
  layout__header: {
    padding: "40px 0",
    display: "flex",
    justifyContent: "center",
  },
  layout__main: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto",
    marginTop: "50px",
    width: "50%",
    [breakpoints.down('xs')]: {
      width: "100%"
    }
  },
  column_right: {
    [breakpoints.up('xl')]: {
      display: "flex",
    },
    [breakpoints.down('sm')]:{
      display: "none"
    }
  }
});

function AuthLayout({ children }){
  const {
    auth_layout,
    layout__image,
    layout__content,
    layout__header,
    layout__main,
    auth__container,
    column_right
  } = makeComponentStyles(styles);
  return (
    <div className={clsx("layout", auth_layout)}>
      <Grid container spacing={0} className={auth__container}>
        <Grid item md={6} className={column_right}>
          <img src={Image} alt="auth image pic" className={layout__image} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Container fluid className={layout__content}>
              <div className={layout__header}>
                <IconButton color="basic" onClick={() => navigate('/')}>
                  <img src={LogoPrimary} alt="logo" />
                </IconButton>
              </div>
              <div className={layout__main}>
                { children }
              </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default AuthLayout;