import React, { useEffect, useState } from "react";
import IconButton from "@components/Buttons/IconButton.jsx";
import Badge from '@material-ui/core/Badge';
import Menu from "@components/Menus/Menu.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Avatar from '@material-ui/core/Avatar';
import { graphql, useStaticQuery } from "gatsby";
import NotificationDesktop from "@static/images/Notification.svg";
import NotificationMobile from "@static/images/Notification_mobile.svg";
import { useIntl, navigate } from "gatsby-plugin-react-intl";
import { getStrapiContentByLang, sortStrapiContentByLang } from "../../heplers";
import { format, utcToZonedTime } from "date-fns-tz";
import { Typography } from "@material-ui/core";
import ButtonLink from "@components/Buttons/ButtonLink.jsx";
import { makeComponentStyles } from "@components/mixins.js";
import { GET_SERVICE_ORDERS } from '@api/modules/requests';
import { decodeTimestamp } from "@src/heplers.js";


const styles = ({ palette, breakpoints }) => ({
    btn_hide: {
        width: '100%',
        justifyContent: 'flex-start',
        color: palette.error.main,
        [breakpoints.up('md')]: {
            fontSize: "18px !important"
        }
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    icon: {
        content: `url(${NotificationMobile})`,
        [breakpoints.up('md')]: {
            content: `url(${NotificationDesktop})`,
        }
    },
    desktopicon: {
        [breakpoints.up('md')]: {
            marginRight: '12px'
        }
    }
});

const appointmentsSerializer = (array) =>
    array.map(({ body }) => ({ ...body, datetime: decodeTimestamp(body.datetime) }));

function getPath(uri) {
    const addr = uri.split('_');
    return `/${addr[0].toLowerCase()}s/${addr[1]}`
}

const Notification = () => {
    const data = useStaticQuery(graphql`{
        allStrapiPlaybill {
            edges {
              node {
                Title
                DateStart
                Image {
                  localFile {
                    publicURL
                  }
                }
                id
                strapiId
                locale
                localizations {
                  id
                }
              }
            }
        }
        allStrapiAd {
            edges {
                node {
                  Title
                  published_at
                  id
                  strapiId
                  locale
                  localizations {
                    id
                  }
                }
            }
        }
    }`)

    const { btn_hide, title, icon, desktopicon } = makeComponentStyles(styles);
    const intl = useIntl();

    const HOURS24 = 86400000;
    const OFFSET = {
        playbills: 2,
        ads: 7,
        appointment: 3
    };

    const Playbills = sortStrapiContentByLang(data.allStrapiPlaybill.edges, true).map(item => getStrapiContentByLang(item.nodes, intl.locale));
    const Ads = sortStrapiContentByLang(data.allStrapiAd.edges, true).map(item => getStrapiContentByLang(item.nodes, intl.locale));

    const [events, setEvents] = useState([]);
    const [checkedEvents, setCheckedEvents] = useState(JSON.parse(localStorage.getItem('events')) || []);

    useEffect(async () => {
        let allEvents = [];
        localStorage.setItem('events', JSON.stringify(checkedEvents));
        try {
            const { data } = await GET_SERVICE_ORDERS(intl.locale);
            appointmentsSerializer(data).reverse();
            allEvents = [...allEvents, ...data.filter(event =>
                (new Date(event.body.datetime * 1000) - HOURS24 * OFFSET.appointment) <= new Date()
                && new Date() <= new Date(event.body.datetime * 1000)
                && !checkedEvents.map(e => e.id).includes(event._id))
                .map(item => {
                    return {
                        Title: item.body.service.title,
                        Datetime: item.body.datetime * 1000,
                        service: item.type.name,
                        id: item._id,
                        path: '/users/account'
                    }
                })
            ];
        } catch (e) { console.log(e) }

        allEvents = [...allEvents, ...Ads.filter(ad =>
            (new Date() - HOURS24 * OFFSET.ads) <= utcToZonedTime(new Date(ad.published_at))
            && !checkedEvents.map(e => e.id).includes(ad.id))
            .map(item => {
                return {
                    Title: item.Title,
                    Datetime: item.published_at,
                    id: item.id,
                    path: getPath(item.id)
                }
            })
        ];

        allEvents = [...allEvents, ...Playbills.filter(event =>
            utcToZonedTime(new Date(event.DateStart) - HOURS24 * OFFSET.playbills) <= new Date()
            && new Date() <= utcToZonedTime(new Date(event.DateStart))
            && !checkedEvents.map(e => e.id).includes(event.id))
            .map(item => {
                return {
                    Title: item.Title,
                    Datetime: item.DateStart,
                    Image: item.Image?.localFile?.publicURL,
                    id: item.id,
                    path: getPath(item.id)
                }
            })
        ];

        setEvents(allEvents);
    }, [checkedEvents]);

    return (
        <PopupState variant="popover">
            {(popupState) => (
                <React.Fragment>
                    <IconButton className={desktopicon} color="basic" {...bindTrigger(popupState)}>
                        <Badge color='error' badgeContent={events.length}>
                            <div className={icon} />
                        </Badge>
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        {
                            events.length === 0 ?
                                <Typography style={{ margin: '0 16px' }}>{intl.formatMessage({ id: 'nonotifications' })}</Typography>
                                :
                                <MenuItem>
                                    <ButtonLink className={btn_hide} onClick={() => setCheckedEvents(checkedEvents => ([...checkedEvents, ...events]))}>
                                        {intl.formatMessage({ id: 'hideNotice' })}
                                    </ButtonLink>
                                </MenuItem>
                        }
                        {
                            events.map(({ Title, Datetime, path, Image, service }, index) => (
                                <MenuItem key={index} style={{ maxWidth: '450px' }} onClick={() => navigate(path)}>
                                    {Image && <Avatar style={{ marginRight: '16px' }} alt={Title} src={Image} />}
                                    <div style={{ overflow: 'hidden' }}>
                                        {service && <Typography className={title}>{service}</Typography>}
                                        <Typography className={title}>{Title}</Typography>
                                        <Typography>{format(utcToZonedTime(new Date(Datetime)), 'dd.MM.yyyy, HH:mm')}</Typography>
                                    </div>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}

export default Notification