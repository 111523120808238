import React, {useContext, useEffect} from "react"
import AuthLayout from "./components/AuthLayout.jsx"
import BaseLayout from "./components/BaseLayout.jsx"
import './layout.css';
import AuthContext from '@src/auth/context'
import {observer} from "mobx-react-lite";
import ApplicationLoader from "@components/Loading/ApplicationLoader";

const types = {
    auth: AuthLayout,
    base: BaseLayout
}

function Layout({children, type = "base", ...rest}) {
    const Layout = types[type];
    const { auth } = useContext(AuthContext);

    if(typeof window === "undefined"){
        return null;
    }

    if (!auth.isRequestSend) {
        auth.checkAuth();
    } else if(!localStorage.getItem("token")){
        auth.setLoading(false);
    }

    return (
        <>
            <Layout {...rest}>
                {children}
            </Layout>
            <ApplicationLoader />
        </>

    )
}

export default observer(Layout);
