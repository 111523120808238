const uuid = require('uuid');

const ContentSerialize = (content) => content
  .map(({ node }) => ({ ...node }))

const NodeSerialize = content => ({ node: { ...content } })

const encodeTimestamp = (value) => new Date(value).getTime() / 1000
const decodeTimestamp = (value) => {
  const leadingZero = (value) => value >= 10 ? value : `0${value}`
  const date = new Date(value * 1000);
  return [
    [
      leadingZero(date.getDate()),
      leadingZero(date.getMonth() + 1),
      date.getFullYear()
    ].join('.'),
    [
      leadingZero(date.getHours()),
      leadingZero(date.getMinutes())
    ].join(':')
  ].join(', ')
}

const getStrapiContentByLang = (content, lang) =>
  (ContentSerialize(content).find(node => node.locale === lang) || {})

const sortStrapiContentByLang = (content, groupID = false) =>{
  const groups = ContentSerialize(content)
    .filter(item => item.locale === "ru-RU")
    .map(({ localizations, ...item }) => {
      return [
        NodeSerialize(item),
        ...localizations.map(({ id }) => {
          const { localizations, ...item } = ContentSerialize(content).find(node => node.strapiId === id)
          return NodeSerialize(item)
        })
      ]
    });
  return groups.map(nodes => {
    return {
      id: groupID ? uuid.v4() : nodes.map(({ node }) => node.strapiId),
      nodes
    }
  })
}


module.exports = {
  encodeTimestamp,
  getStrapiContentByLang,
  sortStrapiContentByLang,
  decodeTimestamp
}
