import React, { useContext, useEffect, useState } from "react"
import { makeComponentStyles } from "@components/mixins.js"
import { IconButton as MuiIconButton } from '@material-ui/core'
import clsx from "clsx"
import { ValidatorContext } from "../Forms/context.js"


const styles = ({ palette }) => ({
  root: {
    color: palette.primary.main,
    padding: 10
  },
  btn_icon_primary: {
    background: palette.primary.light,
    "&:hover": {
      background: palette.primary.light,
    }
  },
  btn_icon_white: {
    background: palette.white.main,
    "&:hover": {
      background: palette.white.main,
    }
  },
  btn_icon_blue: {
    background: palette.primary.main,
    "&:hover": {
      background: palette.primary.main,
    }
  },
  btn_icon_basic: {
    background: "transparent !important"
  },
  disableColor: {
    background: `${palette.secondary.main} !important`
  }
})

function validatorErrors (states, filter){
  return Object.values(states)
    .map(item => item.status)
    .filter(filter);
}

function IconButton({ children, color = "primary", className = "", disabled = false, ...rest }){
  const classes = makeComponentStyles(styles);
  const context = useContext(ValidatorContext);
  const [isDisabled, setDisabled] = useState(disabled);

  useEffect(() => {
    if(context && context.validate){
      setDisabled(Boolean(validatorErrors(context.states, context.filter).length))
    }
  }, [context])

  const props = {
    ...rest,
    className: clsx(
      isDisabled && classes.disableColor,
      classes.root,
      classes[`btn_icon_${color}`],
      className
    ),
    disableRipple: color === "basic",
    disabled: isDisabled,
  }
  return (
    <MuiIconButton
      { ...props }
    >
      { children }
    </MuiIconButton>
  )
}

export default IconButton;
