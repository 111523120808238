import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import {makeComponentStyles} from "../mixins";
import clsx from "clsx";
import {observer} from "mobx-react-lite";
import AuthContext from '@src/auth/context';

const styles = ({ palette }) => ({
    loader: {
        zIndex: 10000000,
        position: "fixed",
        width: "100%",
        height: "100%",
        background: palette.white.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        transition: "opacity .25s ease, visibility .25s"
    },
    visible: {
        opacity: 1,
        visibility: "visible"
    },
    hidden: {
        opacity: 0,
        visibility: 'hidden'
    }
})

function ApplicationLoader(){
    const { auth } = useContext(AuthContext)

    useEffect(() => {
        if(auth.isLoading) document.body.setAttribute("style", "overflow:hidden")
        else document.body.setAttribute("style", "")
    }, [auth.isLoading])

    const {
        loader, visible, hidden
    } = makeComponentStyles(styles);

    return (
        <div className={clsx(
            loader,
            {
                [visible]: auth.isLoading,
                [hidden]: !auth.isLoading
            }
        )}>
            <CircularProgress />
        </div>
    )
}

export default observer(ApplicationLoader);
