import React, { useContext, useState } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "@components/mixins.js"
import AppBar from "@material-ui/core/AppBar"
import Container from "@components/Container/Container.jsx"
import IconButton from "@components/Buttons/IconButton.jsx"
import Toolbar from "@material-ui/core/Toolbar"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@components/Buttons/Button.jsx"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import Link from "@components/Buttons/Link.jsx"
import Typography from "@material-ui/core/Typography"
import Menu from "@components/Menus/Menu.jsx"
import MenuItem from "@material-ui/core/MenuItem"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"
import { Grid } from "@material-ui/core"
import LogoDesctop from "@static/images/logo/LogoTSU.svg"
import ProfileMobile from "@static/images/ProfileBtn_mobile.svg"
import LogoMobile from "@static/images/logo/LogoTSU_mobile_header.svg"
import MenuMobile from "@static/images/MenuBtn_mobile.svg"
import LanguageMobile from "@static/images/Language.svg"
import BotrosatomImg from "@static/images/robot_mobile.svg"
import BotrosatomImg1 from "@static/images/robot_desktop.svg"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import AuthContext from "@src/auth/context"
import { observer } from "mobx-react-lite"
import { useIntl, changeLocale, navigate } from "gatsby-plugin-react-intl"
import Avatar from "@material-ui/core/Avatar"
import MobileNav from "./MobileNav"
import Notification from "./Notification"
import { graphql, useStaticQuery } from "gatsby"
import { getStrapiContentByLang } from "../../heplers"
import { useLocation } from "@reach/router"
import FlagRussia from "../../../static/images/russian_flag.svg"
import FlagChina from "../../../static/images/china_flag.svg"
import FlagBritain from "../../../static/images/britain_flag.svg"

const styles = ({ breakpoints, spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  edges: {
    display: "flex",
    alignItems: "center"
  },
  spacing: {
    justifyContent: "space-evenly",
    paddingLeft: 0,
    paddingRight: 0,
    [breakpoints.down("lg")]: {
      minHeight: "32px"
    },
    [breakpoints.up("lg")]: {
      minHeight: "64px",
      justifyContent: "space-around"
    }
  },
  links: {
    "& $a": {
      textAlign: "center",
      fontSize: "18px"
    }
  },
  btnlinks: {
    textAlign: "center",
    fontSize: "18px !important",
    "@media (max-width: 1450px)": {
      fontSize: "16px !important"
    }
  },
  menulinks: {
    "& $a": {
      fontSize: "18px",
      width: "100%"
    }
  },
  menubtns: {
    "& $button": {
      width: "100%",
      justifyContent: "flex-start"
    }
  },
  menumobile: {
    width: "73px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  avatarsize: {
    width: spacing(8),
    height: spacing(8)
  },
  header_title: {
    fontSize: "20px !important",
    [breakpoints.up("lg")]: {
      fontSize: "24px !important"
    }
  },
  link_padding: {
    paddingBottom: "10px",
    [breakpoints.up("lg")]: {
      paddingBottom: "0px"
    }
  },
  is_desktop: {
    display: "block",
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  is_mobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "block"
    }
  },
  is_desktop_flex: {
    display: "flex",
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  is_mobile_flex: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "flex"
    }
  }
})

const request = graphql`{
    allStrapiSettings {
        edges {
            node {
                locale
                Botrosatom {
                    EnableBot
                    Link
                }
            }
        }
    }
}`

function go(link) {
  window.open(link, "_blank")
}

const Header = () => {
  const {
    root,
    edges,
    spacing,
    links,
    btnlinks,
    menulinks,
    menubtns,
    menumobile,
    avatarsize,
    header_title,
    link_padding,
    is_desktop,
    is_desktop_flex,
    is_mobile,
    is_mobile_flex
  } = makeComponentStyles(styles)
  const location = useLocation()
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  const [mobileNavState, setMobileNavState] = useState(false)
  const { auth } = useContext(AuthContext)

  const intl = useIntl()
  const data = useStaticQuery(request)

  const { Botrosatom } = getStrapiContentByLang(data.allStrapiSettings.edges, intl.locale)

  const handleLogout = () => {
    auth.logout()
    navigate("/")
  }

  const langData = [
    {
      lang: "ru-RU",
      img: FlagRussia,
      title: "RU"
    },
    {
      lang: "en",
      img: FlagBritain,
      title: "EN"
    }, {
      lang: "zh",
      img: FlagChina,
      title: "ZH"
    }
  ]


  const getCurrentLanguage = () => {
    const { pathname } = location
    if (pathname.includes("/ru-RU/")) {
      return "ru-RU"
    } else if (pathname.includes("/en/")) {
      return "en"
    } else {
      return "zh"
    }
  }

  console.log("locationlocationlocationlocation", location)
  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Container fluid className={root}>
        <div className={clsx(edges, is_desktop_flex)}>
          <IconButton color="basic" style={{ padding: 0 }} onClick={() => navigate("/")}>
            <img src={LogoDesctop} alt="Logo" />
          </IconButton>
        </div>
        <div className={clsx(edges, is_mobile_flex)}>
          <PopupState variant="popover">
            {(popupState) => (
              <React.Fragment>
                <IconButton color="basic" {...bindTrigger(popupState)}>
                  <img src={ProfileMobile} alt="Profile" />
                </IconButton>
                {
                  !auth.isAuth
                    ? <Menu className={menulinks} {...bindMenu(popupState)}>
                      <MenuItem><Link to="/users/login"
                                      color="dark">{intl.formatMessage({ id: "login" })}</Link></MenuItem>
                      <MenuItem><Link to="/users/signup"
                                      color="dark">{intl.formatMessage({ id: "signup" })}</Link></MenuItem>
                    </Menu>
                    : <Menu className={menubtns} {...bindMenu(popupState)}>
                      <MenuItem><ButtonLink className={btnlinks}
                                            onClick={() => navigate("/users/account")}>{intl.formatMessage({ id: "profile" })}</ButtonLink></MenuItem>
                      <MenuItem><ButtonLink className={btnlinks}
                                            onClick={handleLogout}>{intl.formatMessage({ id: "logout" })}</ButtonLink></MenuItem>
                    </Menu>
                }
              </React.Fragment>
            )}
          </PopupState>
          {auth.isAuth && <Notification />}
        </div>
        <Container className={is_desktop} style={{ paddingTop: "19px" }}>
          <Typography className={clsx(link_padding, header_title)} variant="h3"
                      style={{ textAlign: "center" }}>{intl.formatMessage({ id: "HeaderTitle" })}</Typography>
          <Grid container className={links}>
            <Grid item lg={6} md={12}>
              <Toolbar className={spacing}>
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <ButtonLink
                        className={btnlinks} {...bindTrigger(popupState)}>{intl.formatMessage({ id: "about" })}</ButtonLink>
                      <Menu className={menulinks} {...bindMenu(popupState)}>
                        <MenuItem><Link to="/about" color="dark">{intl.formatMessage({ id: "about" })}</Link></MenuItem>
                        <MenuItem><Link to="/volunteers" color="dark">{intl.formatMessage({ id: "volunteers" })}</Link></MenuItem>
                        <MenuItem><Link to="/photo_gallery"
                                        color="dark">{intl.formatMessage({ id: "photogallery" })}</Link></MenuItem>
                        <MenuItem><Link to="/video_gallery"
                                        color="dark">{intl.formatMessage({ id: "videogallery" })}</Link></MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <Link className={btnlinks} to="/playbills"
                      color="white">{intl.formatMessage({ id: "playbills" })}</Link>
                <Link className={btnlinks} to="/arrival" color="white">{intl.formatMessage({ id: "arrival" })}</Link>
                <Link className={btnlinks} to="/medical_insurance"
                      color="white">{intl.formatMessage({ id: "medical_insurance" })}</Link>
              </Toolbar>
            </Grid>
            <Grid item lg={6} md={12} className={link_padding}>
              <Toolbar className={spacing}>
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <ButtonLink
                        className={btnlinks} {...bindTrigger(popupState)}>{intl.formatMessage({ id: "life" })}</ButtonLink>
                      <Menu className={menulinks} {...bindMenu(popupState)}>
                        <MenuItem><Link to="/clothes"
                                        color="dark">{intl.formatMessage({ id: "purchases" })}</Link></MenuItem>
                        <MenuItem><Link to="/food" color="dark">{intl.formatMessage({ id: "food" })}</Link></MenuItem>
                        <MenuItem><Link to="/pharmacy"
                                        color="dark">{intl.formatMessage({ id: "pharmacy" })}</Link></MenuItem>
                        <MenuItem><Link to="/medical_services"
                                        color="dark">{intl.formatMessage({ id: "hospital" })}</Link></MenuItem>
                        <MenuItem><Link to="/finance" color="dark">{intl.formatMessage({ id: "atm" })}</Link></MenuItem>
                        <MenuItem><Link to="/leisure"
                                        color="dark">{intl.formatMessage({ id: "freetime" })}</Link></MenuItem>
                        <MenuItem><Link to="/sport_activities" color="dark">{intl.formatMessage({ id: "sport" })}</Link></MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <Link className={btnlinks} to="/housing" color="white">{intl.formatMessage({ id: "housing" })}</Link>
                <Link className={btnlinks} to="/ads" color="white">{intl.formatMessage({ id: "ads" })}</Link>
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <ButtonLink
                        className={btnlinks}  style={{color: "white", fontSize: 16}} {...bindTrigger(popupState)}>  <img
                        src={langData.find(item => item.lang === getCurrentLanguage()).img}
                        alt={`${langData.find(item => item.lang === getCurrentLanguage()).lang}-lang`}
                        style={{ marginRight: 10 }} /> {langData.find(item => item.lang === getCurrentLanguage()).title} <ExpandMoreIcon/></ButtonLink>
                      <Menu className={menubtns} style={{marginTop: 10}} {...bindMenu(popupState)}>
                        {
                          langData.filter(item => item.lang !== getCurrentLanguage()).map((item, index) => (
                            <React.Fragment key={`lang-key-${index}`}>
                              <MenuItem><ButtonLink className={btnlinks}
                                                    onClick={() => changeLocale(`${item.lang}`)}><img
                                src={item.img} alt={`${item.lang}-lang`} style={{ marginRight: 10 }} />{item.title}
                              </ButtonLink></MenuItem>
                            </React.Fragment>
                          ))
                        }
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Toolbar>
            </Grid>
          </Grid>
        </Container>
        <IconButton className={is_mobile} color="basic" onClick={() => navigate("/")}>
          <img src={LogoMobile} alt="Logo" />
        </IconButton>
        <div className={clsx(edges, is_desktop_flex)}>
          {
            Botrosatom.EnableBot === true ?
              <ButtonLink onClick={() => go(Botrosatom?.Link)}>
                <IconButton color="basic">
                  <img src={BotrosatomImg1} alt="Botrosatom" />
                </IconButton>
              </ButtonLink>
              : null
          }
          {
            auth.isAuth ?
              <>
                <Notification />
                <div>
                  <Typography>{auth.user.surname}</Typography>
                  <Typography>{auth.user.name}</Typography>
                </div>
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton color="basic" {...bindTrigger(popupState)}>
                        <Avatar alt={auth.user.name} src={auth.user.avatar ?? " "} className={avatarsize} />
                      </IconButton>
                      <Menu className={menubtns} {...bindMenu(popupState)}>
                        <MenuItem><ButtonLink className={btnlinks}
                                              onClick={() => navigate("/users/account")}>{intl.formatMessage({ id: "profile" })}</ButtonLink></MenuItem>
                        <MenuItem><ButtonLink className={btnlinks}
                                              onClick={handleLogout}>{intl.formatMessage({ id: "logout" })}</ButtonLink></MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </>
              :
              <Button onClick={() => navigate("/users/login")}
                      color="white">{intl.formatMessage({ id: "login" })}</Button>
          }
        </div>
        <div className={clsx(menumobile, is_mobile_flex)}>
          <div>
            {
              Botrosatom.EnableBot === true ?
                <ButtonLink onClick={() => go(Botrosatom?.Link)}>
                  <IconButton color="basic">
                    <img src={BotrosatomImg} alt="Botrosatom" />
                  </IconButton>
                </ButtonLink>
                : null
            }
          </div>
          <PopupState variant="popover">
            {(popupState) => (
              <React.Fragment>
                <IconButton color="basic" style={{color: "white", fontSize: 14}} onClick={() => setMobileNavState(true)} {...bindTrigger(popupState)}>
                  <img
                    src={langData.find(item => item.lang === getCurrentLanguage()).img}
                    alt={`${langData.find(item => item.lang === getCurrentLanguage()).lang}-lang`}
                    style={{ marginRight: 5, width: 24 }} /> {langData.find(item => item.lang === getCurrentLanguage()).title}
                  <ExpandMoreIcon/>
                </IconButton>
                <Menu className={menubtns} {...bindMenu(popupState)}>
                  {
                    langData.filter(item => item.lang !== getCurrentLanguage()).map((item, index) => (
                      <React.Fragment key={`lang-key-${index}`}>
                        <MenuItem><ButtonLink className={btnlinks} onClick={() => changeLocale(`${item.lang}`)}><img
                          src={item.img} alt={`${item.lang}-lang`} style={{ marginRight: 10 }} /> {item.title}
                        </ButtonLink></MenuItem>
                      </React.Fragment>
                    ))
                  }
                </Menu>
              </React.Fragment>
            )}
          </PopupState>

          <IconButton color="basic" onClick={() => setMobileNavState(true)}>
            <img src={MenuMobile} alt="Menu" />
          </IconButton>
        </div>
      </Container>
      {!isDesktop ? <MobileNav state={mobileNavState} setState={setMobileNavState} /> : null}
    </AppBar>
  )
}

export default observer(Header)
