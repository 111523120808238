import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link as GatsbyLink, useIntl } from "gatsby-plugin-react-intl"
import { makeComponentStyles } from "@components/mixins.js"

import clsx from "clsx"

const styles = ({ palette }) => ({
  root:{
    fontFamily: 'Rubik',
    fontSize: '16px',
    textDecoration: "none",
  },
  btn_link_primary: {
    color: palette.primary.main
  },
  btn_link_white: {
    color: palette.white.main
  },
  btn_link_dark: {
    color: palette.text.primary
  }
});

function Link({ children, color = 'primary', className = "", to = "", ...rest }){
  const classes = makeComponentStyles(styles);
  const intl = useIntl();
  const link = `/${intl.locale}${to}`;
  return (
    <AnchorLink
      className={clsx(classes.root, classes[`btn_link_${color}`], className)}
      to={link}
      {...rest}
    >
      {children}
    </AnchorLink>
  )
}

export default Link;
