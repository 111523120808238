import * as React from "react"

import Header from "@components/Header/Header.jsx"
import Footer from "@components/Footer/Footer.jsx"
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"

const styles = () => ({
  page: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    overflow: "hidden"
  },
  footer: {
    marginTop: "auto"
  },
  main: {
    paddingBottom: '50px'
  }
})

function BaseLayout({ children, className = "" }) {
  const {
    page,
    footer,
    main
  } = makeComponentStyles(styles)

  return (
    <div className={clsx(page, className)}>
      <Header />
      <main className={main}>
        {children}
      </main>
      <Footer className={footer} />
    </div>
  )
}

export default BaseLayout;
