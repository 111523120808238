import React from 'react';
import { Backdrop, Modal } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins.js"
import CloseIcon from "@material-ui/icons/Close.js"

const styles = ({ palette, spacing }) => ({
  dialog: {
    color: palette.white.main,
    width: "100%",
    height: "100%",
    padding: "70px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto"
  },
  backdrop: {
    background: "rgba(0,0,0,0.82)",
  },
  btn_close: {
    position: "absolute",
    top: 15,
    right: 24,
  }
})

export const ModalBackdrop = (props) => {
  const { backdrop } = makeComponentStyles(styles);
  props = { ...props, className: backdrop }
  return (<Backdrop {...props} />)
}

function BaseModal({ children, state, setState, className }){

  const {
    dialog, btn_close
  } = makeComponentStyles(styles);

  const handleClose = () => setState(false)

  return (
    <Modal
      open={state}
      onClose={handleClose}
      BackdropComponent={ModalBackdrop}
      closeAfterTransition
      className={className}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={dialog}>
        <CloseIcon className={btn_close} onClick={handleClose} />
        { children }
      </div>
    </Modal>
  )
}

export default BaseModal;
