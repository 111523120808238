import React, {useState} from 'react';
import {Collapse, List, ListItem, ListItemText} from "@material-ui/core";
import {makeComponentStyles} from "@components/mixins";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {changeLocale, useIntl} from "gatsby-plugin-react-intl";
import {navigate} from "gatsby-plugin-react-intl";
import BaseModal from "@components/Dialog/BaseModal.jsx"

const styles = ({ palette, spacing }) => ({
    list_item_text: {
        fontSize: "24px"
    },
    list_nested: {
        paddingLeft: spacing(4),
    },
    list: {
        width: "80%"
    }
})

const CollapsedList = ({ title, children }) => {
    const [open, setOpenState] = useState(false);

    return (
        <>
            <ListItem>
                <ListItemText primary={title} onClick={() => setOpenState(!open)}/>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    )
}

const MenuListItem = ({ children, id, to = null, click = null, nested = false }) => {

    const intl = useIntl();

    const {
        list_item_text,
        list_nested
    } = makeComponentStyles(styles);

    const handleClick = (e) => {
        if(click) return click(e);
        return navigate(to);
    }

    return (
        <ListItem button className={nested ? list_nested : null} onClick={handleClick}>
            <ListItemText className={list_item_text} primary={
                id ? intl.formatMessage({ id }) : children
            } />
        </ListItem>
    )
}

const LanguageItem = ({ local, ...props }) => (
    <MenuListItem
        click={() => changeLocale(local)}
        {...props}
    />
)

function MobileNav(props){

    const {
        modal,
        list,
    } = makeComponentStyles(styles);

    const intl = useIntl();

    return (
        <BaseModal
          { ...props }
          className={modal}
        >
            <List
                component="nav"
                className={list}
            >
                <CollapsedList title={intl.formatMessage({id: "about"})}>
                    <MenuListItem id={"about"} to={"/about"} nested />
                    <MenuListItem id={"volunteers"} to={"/volunteers"} nested />
                    <MenuListItem id={"photogallery"} to={"/photo_gallery"} nested />
                    <MenuListItem id={"videogallery"} to={"/video_gallery"} nested />
                </CollapsedList>
                <MenuListItem id={"playbills"} to={"/playbills"} />
                <MenuListItem id={"arrival"} to={"/arrival"} />
                <MenuListItem id={"medical_insurance"} to={"/medical_insurance"} />
                <CollapsedList title={intl.formatMessage({ id: "life" })}>
                    <MenuListItem id={"purchases"} to={"/clothes"} nested />
                    <MenuListItem id={"food"} to={"/food"} nested />
                    <MenuListItem id={"pharmacy"} to={"/pharmacy"} nested />
                    <MenuListItem id={"hospital"} to={"/medical_services"} nested />
                    <MenuListItem id={"atm"} to={"/finance"} nested/>
                    <MenuListItem id={"freetime"} to={"/leisure"} nested />
                    <MenuListItem id={"sport"} to={"/sport_activities"} nested />
                </CollapsedList>
                <MenuListItem id={"housing"} to={"/housing"} />
                <MenuListItem id={"ads"} to={"/ads"} />
            </List>
        </BaseModal>
    )
}

export default MobileNav;
